import "@fontsource-variable/montserrat";

import { Chart, LineController, CategoryScale, LinearScale, Tooltip, PointElement, LineElement } from "chart.js";

Chart.register(LineController, CategoryScale, LinearScale, Tooltip, PointElement, LineElement);

document.querySelector('[data-role="filter-period"]')?.addEventListener("change", (event) => {
  if (!event.target || !(event.target instanceof HTMLSelectElement)) return;
  globalThis.location.href = event.target.value;
});

const chartElement = document.getElementById("chart");
if (
  chartElement &&
  chartElement instanceof HTMLCanvasElement &&
  chartElement.dataset.labels &&
  chartElement.dataset.data
) {
  new Chart(chartElement, {
    type: "line",
    options: {
      maintainAspectRatio: false,
    },
    data: {
      labels: JSON.parse(chartElement.dataset.labels),
      datasets: [
        {
          data: JSON.parse(chartElement.dataset.data),
          backgroundColor: "#F148A3",
          borderColor: "#F148A3",
          borderWidth: 2,
          tension: 0.1,
        },
      ],
    },
  });
}
